import { type ISODateString } from '@/types/types';
import { format, formatISO, parseISO, sub, subDays } from 'date-fns';

import { DATE_TIME_FORMAT, MOSCOW_TZ_OFFSET, getMoscowDateWithTime } from './get-moscow-date.function';

export const ISODateStringToddMM = (_ISODateString: ISODateString): string =>
  getMoscowDateWithTime(new Date(_ISODateString), DATE_TIME_FORMAT.DDMM);

const ISODateStringToHmm = (_ISODateString: ISODateString): string =>
  getMoscowDateWithTime(new Date(_ISODateString), DATE_TIME_FORMAT.Hmm);

export const getDateHeaderName = (_ISODateString: ISODateString) => {
  if (_ISODateString.includes('00:00:00')) {
    return ISODateStringToddMM(_ISODateString);
  }

  return ISODateStringToHmm(_ISODateString);
};

export const cutHours = (_ISODateString: ISODateString) => _ISODateString.substring(0, 10);

export const isHistoryTime = (_ISODateString: ISODateString) =>
  !_ISODateString.includes('00:00:00');

export const formattedDateToYYMMDD = (_ISODateString: ISODateString) => {
  const date = parseISO(_ISODateString);
  const formattedDate = formatISO(date, { representation: 'date' });

  return formattedDate;
};

export const isValidDateString = (str: string) => String(new Date(str)) !== 'Invalid Date';

export const getUTCFromISOString = (dateStr: string) => {
  const date = new Date(dateStr);
  const utcDate = sub(date, { minutes: new Date().getTimezoneOffset() });

  return utcDate;
};

export const generatePastDates = (count = 30, reverse = true, toISO = false): string[] => {
  const today = new Date();
  const pastDates: string[] = [];

  const localDate = new Date(+today + MOSCOW_TZ_OFFSET);

  for (let i = 0; i < count; i++) {
    const pastDate = subDays(localDate, i);

    const formattedDate = toISO
      ? `${pastDate.toISOString().slice(0, 10)}T00:00:00.000Z`
      : pastDate.toISOString().slice(0, 10);

    pastDates.push(formattedDate);
  }

  return reverse ? pastDates.reverse() : pastDates;
};

// Проверяет формат, но не корректность, например возможен 19 месяц
export const isISODateString = (value: string): boolean => (/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/).test(value);
