import styles from '@/styles/datagrid.module.scss';
import { type GridCellParams } from '@mui/x-data-grid-premium';

import { isHistoryTime } from './date-helpers';
import { type PositionsByDate } from './report-table-data-processor';

const getDescSortedPositionsByDateKeys = (positionsByDate: PositionsByDate) =>
  Object.keys(positionsByDate).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

export const getTodayAndYesterdayDateKey = (positionsByDate: PositionsByDate) => {
  const nonHistoryPositionsByDate = Object.keys(positionsByDate).filter(
    (item) => !isHistoryTime(item),
  );
  const nonHistoryPositionsByDateSorted = nonHistoryPositionsByDate.sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime(),
  );

  return [nonHistoryPositionsByDateSorted[0], nonHistoryPositionsByDateSorted[1]];
};

export const getTodayAndYesterdayValues = (positionsByDate: PositionsByDate) => {
  const todayAndYesterdayValues = [];
  let i = 0;

  for (const _ISODateString of getDescSortedPositionsByDateKeys(positionsByDate)) {
    if (todayAndYesterdayValues.length === 2 || i === 2) {
      break;
    }

    const { organicPosition } = positionsByDate[_ISODateString];

    if (typeof organicPosition === 'number' && organicPosition <= 99990) {
      todayAndYesterdayValues.push(organicPosition);
    }

    i += 1;
  }

  return todayAndYesterdayValues;
};

export const getPromotionRowTopValue = (params: GridCellParams): number | null => {
  let top: number | null = null;

  for (const _ISODateString of getDescSortedPositionsByDateKeys(params.row.positionsByDate)) {
    const { organicPosition } = params.row.positionsByDate[_ISODateString];

    if (typeof organicPosition === 'number' && organicPosition <= 99990) {
      if (top === null) {
        top = organicPosition;
      } else if (organicPosition < top) {
        top = organicPosition;
      }
    }
  }

  return top;
};

export const getPromotionRowDelta = (params: GridCellParams): number | null => {
  const todayAndYesterdayValues = getTodayAndYesterdayValues(params.row.positionsByDate);

  if (todayAndYesterdayValues.length === 2) {
    return todayAndYesterdayValues[1] - todayAndYesterdayValues[0];
  }

  return null;
};

export const getPromotionRowDeltaOfTop = (params: GridCellParams): number | null => {
  const todayAndYesterdayValues = getTodayAndYesterdayValues(params.row.positionsByDate);

  if (typeof todayAndYesterdayValues[0] !== 'number') {
    return null;
  }

  const top = getPromotionRowTopValue(params);

  if (top === null) {
    return null;
  }

  return top - todayAndYesterdayValues[0];
};

export const getTodaysPromotionCPM = (params: GridCellParams): number | null => {
  const todayAndYesterdayKeys = getTodayAndYesterdayDateKey(params.row.positionsByDate);

  return params.row.positionsByDate[todayAndYesterdayKeys[0]].cpm;
};

export const getTodaysPromotionBoost = (params: GridCellParams): number | null => {
  const todayAndYesterdayKeys = getTodayAndYesterdayDateKey(params.row.positionsByDate);
  const promotionJump = params.row.positionsByDate[todayAndYesterdayKeys[0]].promotionJump;

  if (promotionJump === null) {
    return null;
  }

  return Math.abs(promotionJump);
};

interface IColorSort {
  max: number;
  max2: number;
  min2: number;
  min: number;
}

export const getSortValuesForClassName = (values: (number | undefined)[]): IColorSort => {
  const preparedValues = typeof values[0] === 'object' ? values.map((value) => value?.min) : values;
  const sortedAll = preparedValues.filter((item: number | undefined) => item !== undefined).sort((a, b) => b - a);

  const sorted = [...new Set(sortedAll)];

  if (sorted.length === 1) {
    return {
      max: sorted[0],
      max2: sorted[0] - 1,
      min2: 0,
      min: 0,
    };
  }

  if (sorted.length <= 4) {
    return {
      max: sorted?.[0],
      max2: sorted?.[1] || 0,
      min2: sorted?.[2] || 0,
      min: sorted?.[3] || 0,
    };
  }

  return {
    max: sorted[0],
    max2: sorted[1],
    min2: sorted[sorted.length - 2],
    min: sorted[sorted.length - 1],
  };
};

export const getDataGridCellColorClass = (sort: IColorSort | undefined, value: string | number | undefined | { min: number; max: number}): string => {
  if (sort === undefined) {
    return styles.row_gray_light;
  }

  if (!sort) {
    return styles.cell_yellow;
  }

  const preparedValue = typeof value === 'object' && value !== null
    ? value.min
    : Number(value);

  if (preparedValue === undefined) {
    return styles.cell_gray;
  }

  if (preparedValue === sort.max) {
    return styles.cell_dark_green;
  }

  if (preparedValue === sort.max2) {
    return styles.cell_light_green;
  }

  if (preparedValue === sort.min2) {
    return styles.cell_light_red;
  }

  if (preparedValue === sort.min) {
    return styles.cell_dark_red;
  }

  return styles.cell_yellow;
};
