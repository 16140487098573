import { mapObject } from '@/utils/utils';

import { generatePastDates } from './date-helpers';
import { getSortValuesForClassName } from './value-getters';

// article -> date -> data
export type StocksInputData = Record<string, Record<string, StocksArticleDate>>;

interface StocksArticleDate {
  art: number;
  dt: string;
  data: {
    sizes: StocksSize[];
    salePriceU: number;
    rating: number;
  };
}

export interface StocksSize {
  wh: number;
  name: string;
  origName?: string;
  stocks: Stock[];
  salePriceU?: number;
}

export interface Stock {
  wh: number;
  qty: number;
  name: string;
}

export enum STOCKS_METRICS {
  PRICE = 'price',
  RATING = 'rating',
  STOCKS = 'stocks',
  SIZES = 'sizes',
  WH = 'wh',
  ADS = 'ads',
}

export interface StocksDataTable {
  [STOCKS_METRICS.PRICE]: StocksTableRow;
  [STOCKS_METRICS.RATING]: StocksTableRow;
  [STOCKS_METRICS.STOCKS]: Stocks;
  [STOCKS_METRICS.SIZES]: DetailRow;
  [STOCKS_METRICS.WH]: DetailRow;
}

export interface StocksTableRow {
  name: string;
  data: StocksTableCell;
  sort: IStockSort;
}

export interface DetailRow {
  name: string;
  data: StocksTableCell;
  detail?: StocksTableDetailRow[];
}

export interface Stocks {
  name: string;
  data: StocksTableCell;
  articles: string[];
  detail?: StocksTableDetailRow[];
}

export interface StocksTableDetailRow {
  id?: string | number;
  name: string;
  data: StocksTableCell;
  sort: IStockSort;
}

export interface IStockSort {
  max: number;
  max2: number;
  min2: number;
  min: number;
}

export interface IStockCount {
  name: string;
  origName?: string;
  qty: number;
  sizeNames?: string[];
}

export type StocksTableCell = Record<string, number | undefined>;

export enum STATS_PARAMS_NAMES {
  REVENUE = 'Выручка, ₽',
  ORDERS = 'Заказы, шт',
  PRICE = 'Цена, ₽',
  RATING = 'Рейтинг',
  STOCKS = 'Остатки',
  SIZES = 'Размеры',
  WH = 'Склады',
  ADS = 'Внешняя реклама',
}

const sum = (array: number[]): number => array.reduce((acc, item) => acc + item, 0);
const deleteUndefined = (data: (number | undefined)[]): number[] => data.filter((item) => item !== undefined);
const calcNumberAmount = (data: number[]): number | undefined => data.length > 0 ? sum(data) : undefined;

export class StocksCalculator {
  private readonly data: StocksInputData = {};
  private articles: string[] = [];
  private dates: string[] = [];
  private initialData: StocksTableCell = {};
  private today: string = '';

  constructor(data: StocksInputData | null) {
    if (data) {
      this.data = data;
      this.init();
    }
  }

  private readonly init = (): void => {
    this.articles = Object.keys(this.data);
    this.articles.forEach((article) => {
      this.initialData[article] = undefined;
    });
    this.dates = generatePastDates(30);
    this.today = this.dates[this.dates.length - 1];
  };

  isReady = (): boolean => !!this.data;
  getDates = (number = 30): string[] => [...this.dates].reverse().slice(0, number);

  private readonly getInitialData = (): StocksTableCell => ({ ...this.initialData });
  private readonly getArrayInitialData = (number: number, value?: any): any[] => new Array(number).fill(value ?? undefined);

  getArticles = (): string[] => this.articles;

  getLastDayPrice = (): StocksTableCell => {
    const price = {};

    this.articles.forEach((article) => {
      price[article] = this.getPriceByDate(article, this.today);
    });

    return price;
  };

  getStatsValues = (row: StocksTableCell): IStockSort => {
    const values = Object.values(row);

    return getSortValuesForClassName(values);
  };

  getApproximateRating = (): StocksTableCell => {
    const rating = {};

    this.articles.forEach((article) => {
      rating[article] = this.getRateByDate(article, this.today);
    });

    return rating;
  };

  getOrdersByDay = (article: string, date: string, prevDate: string, i): number | undefined => {
    const currentStock = this.getStocks(article, date);
    const prevStock = this.getStocks(article, prevDate);

    if (currentStock === undefined || prevStock === undefined) {
      return undefined;
    }

    const orders = prevStock - currentStock;

    return orders;
  };

  getRatingByArticle = (article: string) => {
    const ratingAll: (number | undefined)[] = [];

    for (const date of this.dates) {
      const rating = this.getRateByDate(article, date);
      ratingAll.unshift(rating);
    }

    const keys = this.getDates();

    return this.arrayToObj(keys, ratingAll);
  };

  private readonly arrayToObj = (keys: string[], values: (number | undefined)[]): Record<string, number | undefined> =>
    keys.reduce((acc: Record<string, number>, key: any, index: string | number) => ({
      ...acc,
      [key]: values?.[index],
    }), {}) as Record<string, number | undefined>;

  getProfit = (): any => {
    /*
      Из вчерашнего значения остатков вычитаем сегодняшнее и так далее.

      Если вчера количество остатков меньше чем сегодня, то мы берем 7 заказов, которые были до этого дня и считаем среднее арифметическое заказов и вводим это число.

      Если количество остатков сегодня и вчера одинаковое - ставим 0 заказов.
    */

    const resultRevenue = {
      revenueYesterday: this.getInitialData(),
      revenue7: this.getInitialData(),
      revenue30: this.getInitialData(),
    };

    const resultOrders = {
      ordersYesterday: this.getInitialData(),
      orders7: this.getInitialData(),
      orders30: this.getInitialData(),
    };

    this.articles.forEach((article) => {
      // От старых к новым
      const dates = this.dates;

      const ordersTemp: (number | undefined)[] = this.getExactCountOrders(dates, article);
      const revenueAll: (number | undefined)[] = [];
      const ordersAll: (number | undefined)[] = [];

      for (let i = 0; i < ordersTemp.length; i++) {
        if (ordersTemp[i] === undefined) {
          ordersAll.push(undefined);
          revenueAll.push(undefined);

          continue;
        }

        const currentOrder = ordersTemp[i];

        let orders: number | undefined;

        if (currentOrder !== undefined && currentOrder >= 0) {
          orders = ordersTemp[i];
        } else {
          orders = this.calcApproximateCountOrders([...ordersTemp], i + 1);

          if (orders !== undefined && orders < 0) {
            orders = undefined;
          }
        }

        if (orders === undefined) {
          ordersAll.push(undefined);
          revenueAll.push(undefined);

          continue;
        }

        if (currentOrder !== undefined && currentOrder < 0) {
          ordersTemp[i] = orders;
        }

        ordersAll.push(orders);

        const price = this.getPriceByDate(article, dates[i]);

        if (price === undefined) {
          revenueAll.push(undefined);

          continue;
        }

        revenueAll.push(orders * price);
      }

      ordersAll.reverse();
      revenueAll.reverse();

      resultOrders.ordersYesterday[article] = calcNumberAmount(deleteUndefined(ordersAll.slice(1, 2)));
      resultOrders.orders7[article] = calcNumberAmount(deleteUndefined(ordersAll.slice(0, 7)));
      resultOrders.orders30[article] = calcNumberAmount(deleteUndefined(ordersAll.slice(0, 30)));

      resultRevenue.revenueYesterday[article] = calcNumberAmount(deleteUndefined(revenueAll.slice(1, 2)));
      resultRevenue.revenue7[article] = calcNumberAmount(deleteUndefined(revenueAll.slice(0, 7)));
      resultRevenue.revenue30[article] = calcNumberAmount(deleteUndefined(revenueAll.slice(0, 30)));
    });

    return {
      ...resultRevenue,
      ...resultOrders,
    };
  };

  getAnalytics = (article: string | undefined): {
    date: string[];
    rows: {
      sales: number[];
      rating: number[];
      price: number[];
      stock: number[];
      revenue: number[];
    };
    top: {
      sales: number;
      rating: number;
      price: number;
      stock: number;
      revenue: number;
    };
  } => {
    const result = {
      date: [...this.dates].reverse().slice(0, 14),
      rows: {
        sales: this.getArrayInitialData(14),
        rating: this.getArrayInitialData(14),
        price: this.getArrayInitialData(14),
        stock: this.getArrayInitialData(14),
        revenue: this.getArrayInitialData(14),
      },
      top: {
        sales: 0,
        rating: 0,
        price: 0,
        stock: 0,
        revenue: 0,
      },
    };

    if (!article) {
      return result;
    }

    // От старых к новым
    const dates = this.dates;

    const ordersTemp: (number | undefined)[] = this.getExactCountOrders(dates, article);
    const revenueAll: (number | undefined)[] = [];
    const ordersAll: (number | undefined)[] = [];
    const priceAll: (number | undefined)[] = [];
    const ratingAll: (number | undefined)[] = [];
    const stocksAll: (number | undefined)[] = [];

    for (let i = 0; i < ordersTemp.length; i++) {
      const price = this.getPriceByDate(article, dates[i]);

      const rating = this.getRateByDate(article, dates[i]);
      const stocks = this.getStocks(article, dates[i]);

      priceAll.push(price);
      ratingAll.push(rating);
      stocksAll.push(stocks);

      if (ordersTemp[i] === undefined) {
        ordersAll.push(undefined);
        revenueAll.push(undefined);

        continue;
      }

      const currentOrder = ordersTemp[i];

      let orders: number | undefined;

      if (currentOrder !== undefined && currentOrder >= 0) {
        orders = ordersTemp[i];
      } else {
        orders = this.calcApproximateCountOrders([...ordersTemp], i + 1);

        if (orders !== undefined && orders < 0) {
          orders = undefined;
        }
      }

      if (orders === undefined) {
        ordersAll.push(undefined);
        revenueAll.push(undefined);

        continue;
      }

      if (currentOrder !== undefined && currentOrder < 0) {
        ordersTemp[i] = orders;
      }

      ordersAll.push(orders);

      if (price === undefined) {
        revenueAll.push(undefined);

        continue;
      }

      revenueAll.push(orders * price);
    }

    ordersAll.reverse();
    revenueAll.reverse();
    priceAll.reverse();
    ratingAll.reverse();
    stocksAll.reverse();

    result.rows.revenue = revenueAll.slice(0, 14);
    result.rows[STOCKS_METRICS.RATING] = ratingAll.slice(0, 14);
    result.rows[STOCKS_METRICS.PRICE] = priceAll.slice(0, 14);
    result.rows.stock = stocksAll.slice(0, 14);
    result.rows.sales = ordersAll.slice(0, 14);

    result.top = {
      revenue: Math.max(...deleteUndefined(result.rows.revenue)),
      [STOCKS_METRICS.RATING]: Math.max(...deleteUndefined(result.rows.rating)),
      [STOCKS_METRICS.PRICE]: Math.max(...deleteUndefined(result.rows[STOCKS_METRICS.PRICE])),
      stock: Math.max(...deleteUndefined(result.rows.stock)),
      sales: Math.max(...deleteUndefined(result.rows.sales)),
    };

    return result;
  };

  getExactCountOrders = (dates, article): (number | undefined)[] => {
    const ordersAll: (number | undefined)[] = [0];

    for (let i = 1; i < dates.length; i++) {
      const orders = this.getOrdersByDay(article, dates[i], dates[i - 1], i);
      ordersAll.push(orders);
    }

    return ordersAll;
  };

  calcApproximateCountOrders = (ordersAll: (number | undefined)[], index: number): number | undefined => {
    const startIndex = index - 7;
    const target = ordersAll.slice(startIndex < 0 ? 0 : startIndex, index - 1);

    if (target.length === 0) {
      return 0;
    }

    const amount = calcNumberAmount(deleteUndefined(target));

    if (amount === undefined) {
      return undefined;
    }

    const approximate = amount / target.length;

    return Math.round(approximate);
  };

  private readonly isExistSizesData = (article: string, date: string): boolean => this.data?.[article]?.[date] && !isArray(this.data?.[article]?.[date].data);

  private readonly getSizesByDay = (article: string, day = ''): StocksSize[] => {
    const date = day || this.today;

    if (this.isExistSizesData(article, date)) {
      return this.data[article][date].data.sizes;
    }

    return [];
  };

  getLastDayStocks = (): {
    [STOCKS_METRICS.SIZES]: StocksTableDetailRow;
    [STOCKS_METRICS.WH]: StocksTableDetailRow;
    [STOCKS_METRICS.STOCKS]: StocksTableCell;
    whDetail: StocksTableDetailRow[];
    sizesDetail: StocksTableDetailRow[];
  } => {
    const stocks = this.getInitialData();
    const whDetail: StocksTableDetailRow[] = [];
    const sizesDetail: StocksTableDetailRow[] = [];
    const initDetail = this.getInitialData();

    const warehouseNames = new Map();
    const warehouse = new Map();
    const warehouseCommon = new Map();

    const sizesStocks = new Map();
    const sizesNames = new Map();

    this.articles.forEach((article) => {
      initDetail[article] = undefined;
    });

    this.articles.forEach((article) => {
      const sizes = this.getSizesByDay(article);
      sizes.forEach((size) => {
        let sizesCount = 0;
        const name = size.origName;
        size.stocks.forEach((stock) => {
          // Подсчет числа остатков для артикула по каждому размеру
          sizesCount += stock.qty;

          // Подсчет общего числа остатков для артикула
          warehouseCommon.set(article, (warehouseCommon.get(article) ?? 0) + stock.qty);

          // Сохраняем имена складов
          warehouseNames.set(stock.wh, stock.name);

          // Подсчет числа остатков для артикулам по каждому складу
          const prevWarehouse = warehouse.get(stock.wh) ?? initDetail;
          const newWarehouseValue = this.calcUpdatedColumn(prevWarehouse, article, stock.qty);
          warehouse.set(stock.wh, newWarehouseValue);
        });

        const prev = sizesStocks.get(name) ?? initDetail;
        const newSizesStocksValue = this.calcUpdatedColumn(prev, article, sizesCount);
        sizesStocks.set(name, newSizesStocksValue);

        // Сохраняем имена размеров
        sizesNames.set(name, name);
      });
    });

    // Форматируем строку общего числа остатков
    warehouseCommon.forEach((commonColumn, key) => {
      stocks[key] = commonColumn;
    });

    // Форматируем строки числа остатков по каждому складу
    warehouse.forEach((detailRow, key) => {
      whDetail.push(
        {
          id: key,
          name: warehouseNames.get(key) || '',
          data: detailRow,
          sort: this.getStatsValues(detailRow),
        },
      );
    });

    // Подсчитываем строку ИТОГО для числа остатков по каждому складу
    const wh = this.calcSummaryDetail(whDetail, STATS_PARAMS_NAMES.WH);

    // Форматируем строки числа остатков по каждому размеру
    sizesStocks.forEach((detailRow, key) => {
      sizesDetail.push(
        {
          id: key,
          name: sizesNames.get(key) || `Размер: ${key}`,
          data: detailRow,
          sort: this.getStatsValues(detailRow),
        },
      );
    });

    sizesDetail.sort((a, b) => +(a.id ?? 0) - +(b.id ?? 0));

    // Подсчитываем строку ИТОГО для числа остатков по каждому размеру
    const sizes = this.calcSummaryDetail(sizesDetail, STATS_PARAMS_NAMES.SIZES);

    return {
      [STOCKS_METRICS.SIZES]: sizes,
      [STOCKS_METRICS.WH]: wh,
      [STOCKS_METRICS.STOCKS]: stocks,
      whDetail,
      sizesDetail,
    };
  };

  getLastDayStocksBySizes = (article: string, name?: string | number): IStockCount[] => {
    const sizes = this.getSizesByDay(article);

    if (name) {
      return sizes.find((size) => size.origName === name)?.stocks.map((stock) => ({
        id: stock.wh,
        name: stock.name,
        qty: stock.qty,
      })) ?? [];
    }

    const stocks = {};
    const stocksNames = {};

    sizes.forEach((size) => {
      if (size?.stocks) {
        size.stocks.forEach((stock) => {
          stocks[stock.wh] = (stocks[stock.wh] || 0) + stock.qty;
          stocksNames[stock.wh] = stock.name;
        });
      }
    });

    const result = Object.keys(stocks).map((wh) => ({
      id: wh,
      name: stocksNames[wh],
      qty: stocks[wh],
    }));

    return result;
  };

  getLastDayWh = (article: string): IStockCount[] => {
    const sizes = this.getSizesByDay(article);
    const stocks = {};
    const stocksNames = {};
    const sizeNames: string[] = [];
    const sizesData = {};

    sizes.forEach((size) => {
      if (size?.origName) {
        sizeNames.push(size.origName);
      }

      if (size?.stocks) {
        size.stocks.forEach((stock) => {
          stocks[stock.wh] = (stocks[stock.wh] || 0) + stock.qty;
          stocksNames[stock.wh] = stock.name;

          if (size?.origName) {
            sizesData[stock.wh] = {
              ...sizesData[stock.wh],
              [size.origName]: stock.qty,
            };
          }
        });
      }
    });

    const result = Object.keys(stocks).map((wh) => {
      const columns = sizeNames.reduce((acc, size) => ({
        ...acc,
        [size]: sizesData[wh]?.[size],
      }), {});

      return {
        id: wh,
        name: stocksNames[wh],
        qty: stocks[wh],
        ...columns,
        sizeNames,
      };
    });

    return result;
  };

  getHistoryPrice = (article: string): any => {
    const sizesData = {};

    let sizeNamesSet = {};

    [...this.dates].reverse().forEach((date) => {
      sizesData[date] ||= {};
      sizesData[date].date = new Date(date);

      const sizes = this.getSizesByDay(article, date);

      sizes.forEach((size) => {
        const price = Math.round((size?.salePriceU || 0) / 100) || 0;
        const name = String(size.origName || size.name);

        if (name) {
          sizesData[date][name] = price;
          sizeNamesSet = { ...sizeNamesSet, [name]: null };
        }
      });
    }, {});

    const initializedSizesData = mapObject(sizesData, (key, value: any) => [
      key,
      {
        ...sizeNamesSet,
        ...value,
      },
    ]);

    return Object.values(initializedSizesData);
  };

  private readonly getFirstNumberSize = (value: string): number => +((/\d+/).exec(value)?.[0] ?? 0);

  getLastDaySizesByStocks = (article: string, wh?: string | number): IStockCount[] => {
    const sizes = this.getSizesByDay(article);

    const result: IStockCount[] = [];
    sizes.forEach((size) => {
      const stocks = size.stocks.filter((item) => wh ? item.wh === wh : true);

      if (stocks.length) {
        const qty = sum(stocks.map((item: Stock) => item.qty));
        result.push({
          origName: size.origName,
          name: size.name,
          qty,
        });
      }
    });

    result.sort((a, b) => this.getFirstNumberSize(a.name) - this.getFirstNumberSize(b.name));

    return result;
  };

  private readonly calcSummaryDetail = (detail: StocksTableDetailRow[], name: string): StocksTableDetailRow => {
    const result = detail.reduce((acc, item): StocksTableCell => {
      const articles = Object.keys(item.data);

      let newValue = { ...acc };
      articles.forEach((article) => {
        if (item.data[article]) {
          newValue = {
            ...newValue,
            [article]: (newValue[article] ?? 0) + 1,
          };
        }
      });

      return newValue;
    }, {});

    return {
      name,
      data: result,
      sort: this.getStatsValues(result),
    };
  };

  private readonly calcUpdatedColumn = (prev: StocksTableCell, article: string, qty: number | undefined): StocksTableCell => {
    const prevValue = prev && Object.keys(prev).includes(article)
      ? prev[article]
      : 0;

    if (prev) {
      const value = {
        ...prev,
        [article]: calcNumberAmount(deleteUndefined([prevValue, qty])),
      };

      return value;
    }

    const value = {
      [article]: calcNumberAmount(deleteUndefined([qty])),
    };

    return value;
  };

  private readonly getPriceByDate = (article: string, date: string): number | undefined => {
    if (!this.data?.[article]?.[date] || isArray(this.data?.[article]?.[date]?.data)) {
      return undefined;
    }

    const currentPrice = this.data[article][date].data?.salePriceU;

    return Math.round(currentPrice / 100);
  };

  private readonly getRateByDate = (article: string, date: string): number | undefined => {
    if (!this.data?.[article]?.[date] || isArray(this.data?.[article]?.[date]?.data)) {
      return undefined;
    }

    return this.data[article][date].data.rating ?? 0;
  };

  private readonly getStocks = (article: string, date: string): number | undefined => {
    if (!this.data?.[article]?.[date] || isArray(this.data?.[article]?.[date]?.data)) {
      return undefined;
    }

    let result = 0;

    const sizes = this.data[article][date].data.sizes;

    sizes.forEach((size) => {
      size.stocks.forEach((stock) => {
        result += stock.qty;
      });
    });

    return result;
  };
}
