export const MOSCOW_TZ_OFFSET = 3 * 60 * 60 * 1000;

export function getMoscowDate(timestamp: Date = new Date()): string {
  return new Date(+timestamp + MOSCOW_TZ_OFFSET).toISOString().slice(0, 10);
}

export enum DATE_TIME_FORMAT {
  YYYYMMDD = 'yyyy-MM-dd',
  DDMM = 'dd.MM',
  Hmm = 'H:mm',
  HHmm = 'HH:mm',
  HHmmss = 'HH:mm:ss',
  ISO = '',
}

export enum DATE_TIME_ROUND {
  NONE = '',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export function getMoscowDateWithTime(
  timestamp: Date = new Date(),
  format: DATE_TIME_FORMAT = DATE_TIME_FORMAT.ISO,
  round: DATE_TIME_ROUND = DATE_TIME_ROUND.NONE,
): string {
  const isoDate = new Date(+timestamp + MOSCOW_TZ_OFFSET).toISOString();

  const year = isoDate.slice(0, 4);
  const month = isoDate.slice(5, 7);
  const day = isoDate.slice(8, 10);

  if (format === DATE_TIME_FORMAT.YYYYMMDD) {
    return `${year}-${month}-${day}`;
  }

  if (format === DATE_TIME_FORMAT.DDMM) {
    return `${day}.${month}`;
  }

  const hours = round === DATE_TIME_ROUND.DAYS ? '00' : isoDate.slice(11, 13);
  const minutes = round === DATE_TIME_ROUND.HOURS || round === DATE_TIME_ROUND.DAYS ? '00' : isoDate.slice(14, 16);
  const seconds = round === DATE_TIME_ROUND.NONE ? isoDate.slice(18, 20) : '00';

  if (format === DATE_TIME_FORMAT.Hmm) {
    return `${hours}:${minutes}`;
  }

  if (format === DATE_TIME_FORMAT.HHmm) {
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  }

  if (format === DATE_TIME_FORMAT.HHmmss) {
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  }

  return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
}
