import { type PhraseRow } from '@/pages/report/utils/comparisons-data-processor';
import { type SeoList } from '@/pages/report/utils/report-table-data-processor';
import { createSlice } from '@reduxjs/toolkit';

export type SeoListExtended = SeoList & {
  foundPhrasesCount: number | null;
  totalPhrasesCount: number | null;
};

export interface ShelvesData {
  checkedPhraseIds: PhraseRow['id'][];
  listId: SeoList['id'];
  topRadioFilter: string;
  mainRadioFilter: string;
  lists: SeoListExtended[];
}

const initialState: ShelvesData = {
  checkedPhraseIds: [],
  listId: 'all',
  topRadioFilter: 'all',
  mainRadioFilter: 'all',
  lists: [],
};

export interface Action<P> {
  type: string;
  payload: P;
}

export const shelvesTabDataSlice = createSlice({
  name: 'shelvesTabDataSlice',
  initialState,
  reducers: {
    setCheckedPhraseIds: (state, action: Action<string[]>) => ({
      ...state,
      checkedPhraseIds: action.payload,
    }),
    setListId: (state, action: Action<string>) => ({
      ...state,
      listId: action.payload,
    }),
    setTopRadioFilter: (state, action: Action<string>) => ({
      ...state,
      topRadioFilter: action.payload,
    }),
    setMainRadioFilter: (state, action: Action<string>) => ({
      ...state,
      mainRadioFilter: action.payload,
    }),
    setLists: (state, action: Action<SeoListExtended[]>) => ({
      ...state,
      lists: action.payload,
    }),
    reset: () => ({ ...initialState }),
  },
});

export const {
  setCheckedPhraseIds,
  setListId,
  setTopRadioFilter,
  setMainRadioFilter,
  setLists,
  reset,
} = shelvesTabDataSlice.actions;

export default shelvesTabDataSlice.reducer;
