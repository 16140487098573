import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import groupBy from 'lodash/groupBy';
import { getMoscowDate } from '@/pages/report/utils/get-moscow-date.function';

export type ProductCarouselAllListType = Array<{
  anchorArticle: number;
  timestamp: string;
  parseMethod: number;
  campaignWbId: number | null;
  position: number;
  boostPosition: number | null;
  cpm: number | null;
}>;

export type ProductCarouselType = {
  dailystats: ProductCarouselDailystatType[];
  anchorArticle: number;
};

export type ProductCarouselDailystatType = {
  article: number;
  timestamp: string;
  date: string;
  parseMethod: number;
  campaignWbId: number;
  position: number;
  boostPosition: number;
  cpm: number;
};

export interface UnknownProductOut {
  article: number;
  imageThumbUrl?: string;
  imageUrl?: string;
  name: string;
  brandName: string;
  subjectWbId?: number;
  kindWbId?: number;
  accountWbId?: number;
  accountTrademark?: string;
  price: number;
  sppPrice: number;
  rating: number;
  feedbackCount: number;
}

export const productCarouselApi = createApi({
  reducerPath: 'productCarouselApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://bmapi2.marpla.ru/',
    prepareHeaders: (headers) => {
      const token = window.localStorage.getItem('bmT') || window.sessionStorage.getItem('bmT');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProductCarousels: builder.query<
      {
        productCarouselByArticle: Map<number, ProductCarouselType>;
      },
      { article: number }
    >({
      query: ({ article }) =>
        `/product_carousel/product_carousels/all?article=${article}&campaignWbId=1&group=true`,
      transformResponse: (result: ProductCarouselAllListType, _, queryArgs) => {
        const { article } = queryArgs;
        const productCarouselByArticle = new Map<number, ProductCarouselType>();
        const groupedDailystatRows = groupBy(result, ([anchorArticle]) => anchorArticle);

        const groupedCarouselRows = Object.entries(
          groupBy(result, ([anchorArticle]) => anchorArticle),
        );

        for (const [anchorArticle] of groupedCarouselRows) {
          const dailystatRows = groupedDailystatRows[+anchorArticle];

          const dailystats: ProductCarouselDailystatType[] = dailystatRows
            ? dailystatRows.map(
                ([, timestamp, parseMethod, campaignWbId, position, boostPosition, cpm]) => ({
                  article,
                  timestamp,
                  date: getMoscowDate(new Date(timestamp)),
                  parseMethod,
                  campaignWbId: campaignWbId || 0,
                  position,
                  boostPosition: boostPosition || 0,
                  cpm: cpm || 0,
                }),
              )
            : [];

          const product = {
            dailystats,
            anchorArticle: +anchorArticle,
          };

          if (!productCarouselByArticle.has(+anchorArticle)) {
            productCarouselByArticle.set(+anchorArticle, product);
          }
        }

        return {
          productCarouselByArticle,
        };
      },
    }),
    getProductInfo: builder.query<UnknownProductOut[], { articles: number[] }>({
      query: ({ articles }) => ({
        url: '/product/products/unknowns',
        params: articles.reduce((params, article) => {
          params.append('articles', article.toString());
          return params;
        }, new URLSearchParams()),
      }),
    }),
    updateDailyStat: builder.mutation<
      ProductCarouselDailystatType[],
      {
        accountArticles: { accountWbId: number; article: number }[];
        anchorArticle: number;
        depth: number;
      }
    >({
      query: (payload) => ({
        url: '/product_carousel/product_carousels/dailystat',
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const { useLazyGetProductCarouselsQuery, useLazyGetProductInfoQuery, useUpdateDailyStatMutation } =
  productCarouselApi;
